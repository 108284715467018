<template>
  <div>
    <flash-message></flash-message>
    <h1 v-if='!isEdit'>
      <a v-tooltip.auto="'Change Name'" href='#' @click.prevent.stop='toggleEdit' class='name__edit' v-if='can'>{{ newName }} <i class='far fa-pencil'></i></i></a>
      <template v-if='!can'>{{newName}}</template>
    </h1>
    <div v-if='isEdit' class='name__edit-form'>
      <form @submit.prevent.stop='save'>
        <input type='text' id='newNameInput' v-model='newName' placeholder="Enter a new name" required="true" v-on:keyup.enter="save">
      </form>
    </div>
  </div>
</template>

<script>
import flash from "../lib/flashes.js";
export default {
  props: ["name", "can", "url"],
  data() {
    return {
     isEdit: false,
     newName: this.name
    };
  },
  methods: {
    toggleEdit () {
      this.isEdit = !this.isEdit;
      this.$nextTick(() => document.querySelector("#newNameInput").focus())
    },
    save () {
      var that = this;
      that.$api.put(that.url, { name: that.newName}).then(function (response) {
        that.isEdit = false
        flash('Name Saved!','success')
      }).catch(error => {
      })
    }
  }
};
</script>

<style scoped>

</style>
