

require("@rails/ujs").start()
import Prism from"./lib/prism.js";
import Vue from "vue/dist/vue.esm";
import AssetTypeField from "./components/asset_type_field.vue";
import CompanyGrid from "./components/grids/CompanyGrid.vue";
import ActivityLogGrid from "./components/grids/ActivityLogGrid.vue";
import AssetTypeIcon from "./components/asset_type_icon.vue";
import AssetTypeSorter from "./components/asset_type_sorter.vue";
import AssetPasswordCopier from "./components/asset_password_copier.vue";
import GroupMemberAdder from "./components/GroupMemberAdder.vue";
import Search from "./components/search.vue";
import Toast from "./components/toast.vue";
import PasswordFolderGroupToggle from "./components/PasswordFolderGroupToggle.vue";
import Updater from "./components/updater.vue";
import Tester from "./components/tester.vue";
import QuickNoter from "./components/quick_noter.vue";
import PasswordEditor from "./components/password_editor.vue";
import ChatPrompter from "./components/ChatPrompter.vue";
import CompanyLogoLoader from "./components/companyLogoLoader.vue";
import ArticleSidebarTabber from "./components/ArticleSidebarTabber.vue";
import CheckAll from "./components/CheckAll.vue";
import BulkVaultPasswordActioner from "./components/BulkVaultPasswordActioner.vue";
import DropdownComponent from "./components/DropdownComponent.vue";
import Mfilter from "./components/Mfilter.vue";
import DashboardPicturePicker from "./components/DashboardPicturePicker.vue";
import QuickAdder from "./components/quick_adder.vue";
import BulkUserImporter from "./components/BulkUserImporter.vue";
import HuduModal from "./components/HuduModal.vue";
import PasswordViewer from "./components/password_viewer.vue";
import CompanyAddressCopier from "./components/CompanyAddressCopier.vue";
import BulkCompanyActioner from "./components/BulkCompanyActioner.vue";
import Onboarder from "./components/Onboarder.vue";
import FavoriteSorter from "./components/FavoriteSorter.vue";
import AssetLayoutChoiceMultiselect from './components/AssetLayoutChoiceMultiselect.vue';
import TemplateChooser from './components/TemplateChooser.vue';
import CheckOutbounder from "./components/CheckOutbounder.vue";
import BulkArticleActioner from "./components/BulkArticleActioner.vue";
import PasswordLocationChanger from "./components/PasswordLocationChanger.vue";
import DashboardDater from "./components/DashboardDater.vue";
import AgreementsViewer from "./components/AgreementsViewer.vue";
import DarkModer from "./components/dark_moder.vue";
import EditableName from "./components/EditableName.vue";
import BulkUserInviter from "./components/BulkUserInviter.vue";
import NewArticleButton from "./components/NewArticleButton.vue";
import Mapbox from "./components/Mapbox.vue";
import Legacier from "./components/legacier.vue";
import NameList from "./components/NameList.vue";
import OtpCopier from "./components/OtpCopier.vue";
import Copier from "./components/copier.vue"
import ArticleWriter from "./components/ArticleWriter.vue"
import ArticleExpirer from "./components/ArticleExpirer.vue"
import Restricter from "./components/restricter.vue"
import LayoutSorter from "./components/layout_sorter.vue"
import CustomFastFacter from "./components/CustomFastFacter.vue"
import VaultPasswordCopier from "./components/VaultPasswordCopier.vue"
import Alerter from "./components/alerter.vue"
import Vault from "./components/vault.vue"
import Pinner from "./components/Pinner.vue"
import SecureNoter from "./components/SecureNoter.vue"
import SecureNoteRevealer from "./components/SecureNoteRevealer.vue"

import EmailAutofocuser from "./components/EmailAutofocuser.vue";
import Sidebar from "./components/sidebar.vue"
import BulkAssetActioner from "./components/BulkAssetActioner.vue"
import BulkPasswordActioner from "./components/BulkPasswordActioner.vue"
import DomainInfo from "./components/domain_info.vue";
import FolderEditor from "./components/folder_editor.vue";
import CardTag from "./components/CardTag.vue";
import FolderChooser from "./components/folder_chooser.vue";
import DatePicker from "./components/date_picker.vue";
import CompanyAdder from "./components/CompanyAdder.vue";
import Roler from "./components/roler.vue";
import Progression from "./components/progression.vue";
import Rematcher from "./components/rematcher.vue";
import SyncBar from "./components/sync_bar.vue";
import Sharer from "./components/sharer.vue";
import Relativer from "./components/relativer.vue";
import Contenter from "./components/contenter.vue";
import Searcher from "./components/searcher.vue";
import PasswordColorPicker from "./components/PasswordColorPicker.vue";
import PasswordTagger from "./components/PasswordTagger.vue";
import Writer from "./components/writer.vue";
import Swagger from "./components/Swagger.vue";

import Permissioner from "./components/permissioner.vue";
import Embedder from "./components/embedder.vue";
import PortalExpander from "./components/portal_expander.vue";
import Dropper from "./components/dropper.vue";
import ProcessChart from "./components/process_chart.vue";
import QuickRematcher from "./components/quick_rematcher.vue";
import DashboardItemSorter from "./components/DashboardItemSorter.vue";
import QualityChart from "./components/quality_chart.vue";
import Duplicator from "./components/duplicator.vue";
import ProcedureQuickViewer from "./components/procedure_quick_viewer.vue";
import Importer from "./components/importer/importer.vue";
import Linker from "./components/linker.vue";
import Tagger from "./components/tagger.vue";
import StateRegionPicker from "./components/state_region_picker.vue";
import BasicInfoExpander from "./components/basic_info_expander.vue";
import Mover from "./components/mover.vue"
import ManageModal from "./components/manage_modal.vue"
import Relater from "./components/relater.vue";
import Processor from "./components/processor.vue";
import ProcessorToolbar from "./components/processor/ProcessorToolbar.vue";
import Icon from "./components/icon.vue";
import LiongardTabber from "./components/LiongardTabber.vue";
import CompanyGrabber from "./components/company_grabber.vue";
import FileManager from "./components/file_manager.vue"
import PhotoManager from "./components/photo_manager.vue"
import AppHeader from "./components/AppHeader.vue"
import Ringer from "./components/ringer.vue"
import Index from "./components/index.vue";
import Commenter from "./components/commenter.vue";
import AssetLayoutSwitcher from "./components/AssetLayoutSwitcher.vue";
import ExporterCheckAll from "./components/ExporterCheckAll.vue";
import Dnser from "./components/dnser.vue";
import OtpInputer from "./components/otp_inputer.vue";
import OtpGenerator from "./components/otp_generator.vue";
import Timer from "./components/timer.vue";
import PortalPasswordViewer from "./components/portal_password_viewer.vue";
import PortalQuickAdder from "./components/portal-quick-adder.vue";
import axios from "axios";
import VueFlashMessage from 'vue-flash-message';
import vueDebounce from 'vue-debounce'
import VueLoadImage from 'vue-load-image'
import Loader from './components/loader.vue'
import vueCountryRegionSelect from 'vue-country-region-select'
import store from "./store"
import FloatingVue from 'floating-vue'




document.addEventListener('DOMContentLoaded', () => {

  window.Prism = window.Prism || {};
  window.Prism.manual = true;
  Vue.use(VueFlashMessage);
  Vue.use(vueCountryRegionSelect);


  Vue.use(FloatingVue);

  FloatingVue.options.themes.tooltip.disabled = window.innerWidth <= 768
  //FloatingVue.options.strategy = (window.innerWidth <= 768 ? "fixed" : "absolute");

  Vue.use(vueDebounce, {
    listenTo: ['oninput', 'onkeyup']
  })

  Vue.use({
      install (Vue) {
      Vue.prototype.$api = axios.create({
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
    }
  })

  const app = new Vue({
    el: '#app',
    store,
    data: function () {
      return {
        forceClose: false
      }
    },
    mounted () {
       Prism.highlightAll()
    },
    components: {
      'vue-load-image': VueLoadImage,
      'loader': Loader,
      Index,
      AssetTypeField,
      AssetLayoutChoiceMultiselect,
      OtpCopier,
      Mfilter,
      PasswordFolderGroupToggle,
      PhotoManager,
      ProcedureQuickViewer,
      AssetTypeIcon,
      AssetTypeSorter,
      BulkArticleActioner,
      FavoriteSorter,
      NewArticleButton,
      CompanyAddressCopier,
      AppHeader,
      LiongardTabber,
      DropdownComponent,
      BulkUserInviter,
      EditableName,
      Relativer,
      Pinner,
      ActivityLogGrid,
      Embedder,
      CheckAll,
      DashboardPicturePicker,
      ExporterCheckAll,
      Timer,
      Sharer,
      Sidebar,
      CompanyLogoLoader,
      StateRegionPicker,
      PasswordLocationChanger,
      AgreementsViewer,
      TemplateChooser,
      FileManager,
      QuickRematcher,
      SecureNoter,
      SecureNoteRevealer,
      ArticleSidebarTabber,
      BulkUserImporter,
      QuickNoter,
      Alerter,
      EmailAutofocuser,
      AssetLayoutSwitcher,
      AssetPasswordCopier,
      Dnser,
      Mover,
      Onboarder,
      QuickAdder,
      OtpGenerator,
      BulkAssetActioner,
      BulkPasswordActioner,
      VaultPasswordCopier,
      CustomFastFacter,
      OtpInputer,
      PasswordTagger,
      BulkCompanyActioner,
      ManageModal,
      ProcessChart,
      HuduModal,
      QualityChart,
      PortalExpander,
      CompanyGrabber,
      CompanyAdder,
      ArticleExpirer,
      Duplicator,
      Vault,
      LayoutSorter,
      Ringer,
      DashboardItemSorter,
      Dropper,
      Relater,
      Searcher,
      Legacier,
      BasicInfoExpander,
      PortalPasswordViewer,
      PortalQuickAdder,
      Roler,
      Importer,
      FolderEditor,
      FolderChooser,
      Tester,
      BulkVaultPasswordActioner,
      Tagger,
      DarkModer,
      DashboardDater,
      Mapbox,
      NameList,
      CardTag,
      Updater,
      Writer,
      Permissioner,
      Progression,
      GroupMemberAdder,
      CompanyGrid,
      ChatPrompter,
      Commenter,
      Contenter,
      Processor,
      ProcessorToolbar,
      DatePicker,
      Icon,
      SyncBar,
      CheckOutbounder,
      PasswordColorPicker,
      ArticleWriter,
      Rematcher,
      Linker,
      Toast,
      DomainInfo,
      Search,
      PasswordViewer,
      PasswordEditor,
      Copier,
      Restricter,
      Swagger,
    }
  })
})
