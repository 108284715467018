<template>
  <div style='position: relative;'>
    <div class='button-group' v-if='!isShown'>
      <template v-if='!condensed'>
        <a href='#' v-if='!isCopied' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'><i class='fas fa-copy'></i>OTP</a>
        <a href='#' v-if='isCopied' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'>Copied!</a>
      </template>
      <template v-if="condensed">
        <a href='#' title='Copy OTP' @click.prevent.stop='copy($event)' class='button button--small button--secondary'><i class='fas fa-lock'></i></a>
      </template>
    </div>
  </div>
</template>

<script>

import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";

export default {
  props: ["url", "condensed"],
  methods: {
    copy (e) {
      var that = this;

      var xhttp = new XMLHttpRequest();
      xhttp.open("GET", that.url, false);
      xhttp.send();
      var response = JSON.parse(xhttp.responseText);

      that.setClipboard(response["otp"], e)
      that.count = response["time_left"]
    },
    setClipboard(value, e) {

      var that = this;

      ClipboardFunctions.copyText(value);
      that.isCopied = true;
      
      setTimeout(function(){ that.isCopied = false; }, 2000);

      that.flash(`Copied OTP - ${that.count} second(s) left!`, 'success', 
        {
          timeout: 3000,
          important: true
        }
      );

      
    }
  },
  computed: {
    countPercentage () {
      return ((1 - (this.count / 30)) * 100)
    }
  },
  data () {
    return {
      active: false,
      isShown: false,
      option: 6,
      otp: "",
      isCopied: false,
      count: 30,
      error: false
    }
  }
}
</script>

