<template>
  <div>
    <flash-message></flash-message>
    <div class='password-circle__picker'>

      <input type='hidden' :value='selected' name='asset_password[color]'>
      <a href='#'  @click.prevent.stop='select("")'>
        <span :class='{"password-circle": true, "selected big": selected == ""}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("red")'>
        <span :class='{"password-circle password-circle--red": true, "selected big": selected == "red"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("blue")'>
        <span :class='{"password-circle password-circle--blue": true, "selected big": selected == "blue"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("green")'>
        <span :class='{"password-circle password-circle--green": true, "selected big": selected == "green"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("purple")'>
        <span :class='{"password-circle password-circle--purple": true, "selected big": selected == "purple"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("pink")'>
        <span :class='{"password-circle password-circle--pink": true, "selected big": selected == "pink"}'></span>
      </a>
      <a href='#'  @click.prevent.stop='select("yellow")'>
        <span :class='{"password-circle password-circle--yellow": true, "selected big": selected == "yellow"}''></span>
      </a>
    </div>
  </div>
    
</template>

<script>
import flash from "../lib/flashes.js";
export default {
  props: ["existing", "update_url", "can"],
  data: function () {
    return {
      selected: this.existing || ""
    }
  },
  
  methods: {
    select (color) {
      if (!this.can) {
        return;
      }
      this.selected = color;

      var that = this;
      that.$api.put(that.update_url, { "asset_password": {"color": that.selected}}).then(function (response) {
        flash('Color Updated!','success')
      }).catch(error => {
        console.log(error)
      })

    }
  }
}
</script>

